/* @import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Rubik:wght@300;400;700;900&display=swap'); */

html,
body {
  height: 100vh;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat';
}
